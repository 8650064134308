import { FC, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDays, faCircleUser, faCommentDots, faIdCard } from '@fortawesome/free-regular-svg-icons';
import { faArrowRightFromBracket, faBars, faBell, faChevronDown, faCrosshairs, faGlobe, faScrewdriverWrench, faPenNib, faRightToBracket, faShop, faTableColumns, faUsers, faPeopleGroup, faUser, faPersonRifle, faPercent } from '@fortawesome/free-solid-svg-icons';
import useDropdown from 'react-dropdown-hook';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import Scrollbars from 'react-custom-scrollbars';

import { colorStack } from '../../style/colorStack';
import { logout } from '../../actions/securityActions';
import { setCookie } from '../../tools/cookies';
import { IState } from '../../reducers';
import { IUserProfileReducer } from '../../reducers/profileReducer';
import { ContextMenu } from './ContextMenu';
import { PhotoComponent } from './PhotoComponent';
import { fontSizeAndHeight } from '../../style/fontSizes';
import { INotificationReducer } from '../../reducers/notificationReducer';
import { media } from '../../style/breakpoints';
import { TrackGoogleAnalyticsEvent } from '../../tools/ga';

const Wrapper = styled.div`
    height: 64px;
    width: 100%;
    .photo-component img {
        width: 34px;
        margin-right: .5rem;
    }
    > div {
        width: 100%;
        padding: 0 1rem;
        margin: 0 auto;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        img {
            width: 64px;
        }
        > a {
            display: flex;
            align-items: center;
            color: ${colorStack.white};
            ${fontSizeAndHeight[25]};
            span span {
                ${fontSizeAndHeight[16]};
            }
        }
    }
`;

const UserBox = styled.div`
    position: relative;
    display: flex;
    margin-left: 1rem;
    align-items: center;
    > svg, > img {
        ${fontSizeAndHeight[31]};
        color: ${colorStack.blue};
        cursor: pointer;
        width: 32px;
    }
    .photo-component {
        margin-right: .5rem;
        height: 32px;
    }
    span {
        cursor: pointer;
        color: ${colorStack.white};
    }
    > div {
        cursor: pointer;
        position: relative;
        align-items: center;
        display: flex;
        &:nth-child(3) {
            right: 0;
        }
        > svg {
            ${fontSizeAndHeight[16]};
            margin-left: .5rem;
        }
    }
`;

const CustomLinks = styled(Link)`
    ${fontSizeAndHeight[16]};
    color: ${colorStack.white};
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    position: relative;
    margin-right: 1.5rem;
    svg {
        margin-right: .5rem;
        color: ${colorStack.middleOrange};
    }
    &::after {
        opacity: 0;
        transform: scale(0);
        transform-origin: center;
        content: '';
        position: absolute;
        bottom: -6px;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: ${colorStack.middleBlue};
        transition: opacity 300ms, transform 300ms;
    }
    &:hover {
        transition: color .3s;
        color: ${colorStack.middleOrange};
        &::after {
            opacity: 1;
            transform: scale(1);
        }
    }
`;

const CustomAdLinks = styled.div`
    ${fontSizeAndHeight[16]};
    color: ${colorStack.white};
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    position: relative;
    margin-right: 1.5rem;
    svg {
        margin-right: .5rem;
        color: ${colorStack.middleOrange};
    }
    &::after {
        opacity: 0;
        transform: scale(0);
        transform-origin: center;
        content: '';
        position: absolute;
        bottom: -6px;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: ${colorStack.middleBlue};
        transition: opacity 300ms, transform 300ms;
    }
    &:hover {
        transition: color .3s;
        color: ${colorStack.middleOrange};
        &::after {
            opacity: 1;
            transform: scale(1);
        }
    }
`;

const Right = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

const Mobile = styled.div`
    padding: 0 1rem;
    > svg {
        color: ${colorStack.white};
        cursor: pointer;
        ${fontSizeAndHeight[31]};
    }
`;

const InnerMenu = styled.div`
    display: flex;
    gap: 1.5rem;
    flex-direction: column;
`;

const MobileMenu = styled.div`
    position: absolute;
    top: 64px;
    right: 0;
    height: 100vh;
    background: rgb(41, 42, 41);
    width: 80%;
    display: flex;
    padding: 2rem;
    z-index: 99999;
    flex-direction: column;
    a {
        ${fontSizeAndHeight[16]};
        margin-right: 0;
    }
`;

const NotificationBox = styled.div`
    margin: 0 2rem 0 auto;
    cursor: pointer;
    ${media.desktop`
        margin-left: 3rem;
    `};
`;

const Bell = styled.div`
    position: relative;
    svg {
        color: ${colorStack.white};
        ${fontSizeAndHeight[25]};
    }
    > span {
        position: absolute;
        top: -8px;
        right: -8px;
        min-width: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: ${colorStack.red};
        color: ${colorStack.white};
        border-radius: 100%;
        padding: 0.3rem;
        ${fontSizeAndHeight[13]};
    }
`;

const UserName = styled.span`
    font-weight: 600;
`;

export const TopMenu: FC = () => {
    const location = useLocation();
    const intl = useIntl();
    const history = useNavigate();
    const dispatch = useDispatch();
    const { userProfile, isOrganizator, notificationCount } = useSelector<IState, IUserProfileReducer & INotificationReducer>(state => ({
        ...state.profile,
        ...state.notification
    }));
    const [wrapperMobileRef, dropdownMobileOpen, toggleMobileDropdown, closeMobileDropdown] = useDropdown();
    const [wrapperRef, dropdownOpen, toggleDropdown] = useDropdown();

    useEffect(() => {
        closeMobileDropdown();
    }, [location]);

    const logoutHandler = () => {
        setCookie('PlayAirAuth', '', 0);
        dispatch<any>(logout());
        history('/');
    };

    const userMenuToggle = () => {
        toggleDropdown();
    };

    const adClick = () => {
        TrackGoogleAnalyticsEvent('AdClick', 'B.L.A.S.G', '');
        history('/big-event/8a801f788e9da6a2018ead61d022050c');
    };

    return (
        <Wrapper>
            {window.innerWidth >= 1024 ? (
                <div>
                    <Link to="/">
                        <img src={`${process.env.PUBLIC_URL}/img/logo_white.png`} alt="PlayAir logo" loading="lazy" />
                        <span>Playair.pro</span>
                    </Link>
                    <Right>
                        <CustomLinks to="/events"><FontAwesomeIcon icon={faCrosshairs} />
                            {intl.formatMessage({ id: 'topBar.events' })}
                        </CustomLinks>
                        <CustomLinks to="/big-events"><FontAwesomeIcon icon={faPersonRifle} />
                            {intl.formatMessage({ id: 'topBar.bigEvents' })}
                        </CustomLinks>
                        <CustomLinks to="/places"><FontAwesomeIcon icon={faGlobe} />
                            {intl.formatMessage({ id: 'topBar.places' })}
                        </CustomLinks>
                        <CustomLinks to="/players"><FontAwesomeIcon icon={faUsers} />
                            {intl.formatMessage({ id: 'topBar.players' })}
                        </CustomLinks>
                        <CustomLinks to="/services"><FontAwesomeIcon icon={faScrewdriverWrench} />
                            {intl.formatMessage({ id: 'topBar.services' })}
                        </CustomLinks>
                        <CustomLinks to="/teams"><FontAwesomeIcon icon={faPeopleGroup} />
                            {intl.formatMessage({ id: 'topBar.groups' })}
                        </CustomLinks>
                        <CustomLinks to="/bazarek"><FontAwesomeIcon icon={faShop} />
                            {intl.formatMessage({ id: 'topBar.marketplace' })}
                        </CustomLinks>
                        <CustomLinks to="/discount"><FontAwesomeIcon icon={faPercent} />
                            {intl.formatMessage({ id: 'topBar.discount' })}
                        </CustomLinks>
                        <CustomLinks to="/chats"><FontAwesomeIcon icon={faCommentDots} />
                            {intl.formatMessage({ id: 'topBar.chat' })}
                        </CustomLinks>
                        {!userProfile &&
                            <>
                                <CustomLinks to="/login"><FontAwesomeIcon icon={faRightToBracket} />
                                    {intl.formatMessage({ id: 'topBar.login' })}
                                </CustomLinks>
                                <CustomLinks to="/register"><FontAwesomeIcon icon={faPenNib} />
                                    {intl.formatMessage({ id: 'topBar.register' })}
                                </CustomLinks>
                            </>
                        }
                        {userProfile &&
                            <>
                                <NotificationBox onClick={() => history('notifications')}>
                                    <Bell>
                                        <FontAwesomeIcon icon={faBell} />
                                        {notificationCount > 0 &&
                                            <span>{notificationCount}</span>
                                        }
                                    </Bell>
                                </NotificationBox>
                                <UserBox ref={wrapperRef} onClick={userMenuToggle}>
                                    {userProfile?.pictureId ? (
                                        <PhotoComponent photoId={userProfile?.pictureId} rounded width={32} />
                                    ) : (
                                        <FontAwesomeIcon icon={faCircleUser} />
                                    )}
                                    <div>
                                        <UserName>{userProfile?.nickName || userProfile?.firstName || ''}</UserName>
                                        <FontAwesomeIcon icon={faChevronDown} color={colorStack.middleOrange} />
                                    </div>
                                    {dropdownOpen &&
                                        <ContextMenu
                                            links={[
                                                {
                                                    label: intl.formatMessage({ id: 'topBar.profile' }),
                                                    ico: faUser,
                                                    visible: true,
                                                    action: () => history('/profile/me')
                                                },
                                                {
                                                    label: intl.formatMessage({ id: 'topBar.dashboard' }),
                                                    ico: faCalendarDays,
                                                    visible: true,
                                                    action: () => history('/profile/me/dashboard')
                                                },
                                                {
                                                    label: intl.formatMessage({ id: 'topBar.tools' }),
                                                    ico: faScrewdriverWrench,
                                                    visible: true,
                                                    action: () => history('/profile/me/tools')
                                                },
                                                {
                                                    label: intl.formatMessage({ id: 'topBar.orgPanel' }),
                                                    ico: faTableColumns,
                                                    visible: isOrganizator,
                                                    action: () => history('/org-dashboard')
                                                },
                                                {
                                                    label: intl.formatMessage({ id: 'topBar.logout' }),
                                                    ico: faArrowRightFromBracket,
                                                    action: logoutHandler,
                                                    visible: true
                                                }
                                            ]}
                                        />
                                    }
                                </UserBox>
                            </>
                        }
                    </Right>
                </div>
            ) : (
                <Mobile ref={wrapperMobileRef}>
                    <Link to="/">
                        <img src={`${process.env.PUBLIC_URL}/img/logo_white.png`} alt="PlayAir logo" loading="lazy" />
                    </Link>
                    <NotificationBox onClick={() => history('notifications')}>
                        <Bell>
                            <FontAwesomeIcon icon={faBell} />
                            {notificationCount > 0 &&
                                <span>{notificationCount}</span>
                            }
                        </Bell>
                    </NotificationBox>
                    <FontAwesomeIcon icon={faBars} onClick={toggleMobileDropdown} />
                    {dropdownMobileOpen &&
                        <MobileMenu>
                            <Scrollbars>
                                <InnerMenu>
                                    <CustomLinks to="/profile/me"><FontAwesomeIcon icon={faUser} />
                                        {intl.formatMessage({ id: 'topBar.profile' })}
                                    </CustomLinks>
                                    <CustomLinks to="/profile/me/dashboard"><FontAwesomeIcon icon={faIdCard} />
                                        {intl.formatMessage({ id: 'topBar.dashboard' })}
                                    </CustomLinks>
                                    <CustomLinks to="/events"><FontAwesomeIcon icon={faCrosshairs} />
                                        {intl.formatMessage({ id: 'topBar.events' })}
                                    </CustomLinks>
                                    <CustomLinks to="/big-events"><FontAwesomeIcon icon={faPersonRifle} />
                                        {intl.formatMessage({ id: 'topBar.bigEvents' })}
                                    </CustomLinks>
                                    <CustomLinks to="/places"><FontAwesomeIcon icon={faGlobe} />
                                        {intl.formatMessage({ id: 'topBar.places' })}
                                    </CustomLinks>
                                    <CustomLinks to="/players"><FontAwesomeIcon icon={faUsers} />
                                        {intl.formatMessage({ id: 'topBar.players' })}
                                    </CustomLinks>
                                    <CustomLinks to="/profile/me/tools"><FontAwesomeIcon icon={faScrewdriverWrench} />
                                        {intl.formatMessage({ id: 'topBar.tools' })}
                                    </CustomLinks>
                                    {isOrganizator &&
                                        <CustomLinks to="/org-dashboard"><FontAwesomeIcon icon={faTableColumns} />
                                            {intl.formatMessage({ id: 'topBar.orgPanel' })}
                                        </CustomLinks>
                                    }
                                    <CustomLinks to="/teams"><FontAwesomeIcon icon={faPeopleGroup} />
                                        {intl.formatMessage({ id: 'topBar.groups' })}
                                    </CustomLinks>
                                    <CustomLinks to="/bazarek"><FontAwesomeIcon icon={faShop} />
                                        {intl.formatMessage({ id: 'topBar.marketplace' })}
                                    </CustomLinks>
                                    <CustomLinks to="/discount"><FontAwesomeIcon icon={faPercent} />
                                        {intl.formatMessage({ id: 'topBar.discount' })}
                                    </CustomLinks>
                                    <CustomLinks to="/chats"><FontAwesomeIcon icon={faCommentDots} />
                                        {intl.formatMessage({ id: 'topBar.chat' })}
                                    </CustomLinks>
                                    <CustomLinks to="/services"><FontAwesomeIcon icon={faScrewdriverWrench} />
                                        {intl.formatMessage({ id: 'topBar.services' })}
                                    </CustomLinks>
                                    {!userProfile &&
                                        <>
                                            <CustomLinks to="/login"><FontAwesomeIcon icon={faRightToBracket} />
                                                {intl.formatMessage({ id: 'topBar.login' })}
                                            </CustomLinks>
                                            <CustomLinks to="/register"><FontAwesomeIcon icon={faPenNib} />
                                                {intl.formatMessage({ id: 'topBar.register' })}
                                            </CustomLinks>
                                        </>
                                    }
                                    {userProfile &&
                                        <CustomLinks as="button" onClick={logoutHandler} type="button"><FontAwesomeIcon icon={faArrowRightFromBracket} />
                                            {intl.formatMessage({ id: 'topBar.logout' })}
                                        </CustomLinks>
                                    }
                                </InnerMenu>
                            </Scrollbars>
                        </MobileMenu>
                    }
                </Mobile>
            )}
        </Wrapper>
    );
};