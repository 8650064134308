import { FC, Suspense, lazy } from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { IUserProfileReducer } from '../../reducers/profileReducer';
import { IState } from '../../reducers';
import { setCookie } from '../../tools/cookies';
import { Spinner } from '../common/Spinner/Spinner';

const AllServices = lazy(() => import('./Services/AllServices'));
const EditService = lazy(() => import('./Services/EditService'));
const SingleService = lazy(() => import('./Services/SingleService'));
const Equipment = lazy(() => import('./Profile/Stuff/Equipment'));
const Gear = lazy(() => import('./Profile/Stuff/Gear'));
const Replica = lazy(() => import('./Profile/Stuff/Replica'));
const Discount = lazy(() => import('./Discount/Discount'));
const Administration = lazy(() => import('./Administration'));
const SingleGroup = lazy(() => import('./Groups/SingleGroup'));
const EditGroup = lazy(() => import('./Groups/EditGroup'));
const Notifications = lazy(() => import('./Notifications'));
const Terms = lazy(() => import('./StaticPages/Terms'));
const EditOffer = lazy(() => import('./Offers/EditOffer'));
const SingleOffer = lazy(() => import('./Offers/SingleOffer'));
const Offer = lazy(() => import('./Offers'));
const EditSpot = lazy(() => import('./Arenas/EditSpot'));
const Help = lazy(() => import('./StaticPages/Help'));
const Home = lazy(() => import('./Home/HomeNew'));
const Contact = lazy(() => import('./StaticPages/Contact'));
const Register = lazy(() => import('./Security/Register'));
const Soon = lazy(() => import('./StaticPages/Soon'));
const Login = lazy(() => import('./Security/Login'));
const Profile = lazy(() => import('./Profile/Profile'));
const AllArenas = lazy(() => import('./Arenas/AllArenas'));
const SingleArena = lazy(() => import('./Arenas/SingleArena'));
const AllEvents = lazy(() => import('./Events/AllEvents'));
const AllBigEvents = lazy(() => import('./BigEvents'));
const SingleEvent = lazy(() => import('./Events/SingleEvent'));
const SingleBigEvent = lazy(() => import('./BigEvents/SingleBigEvent'));
const BigEventAdministration = lazy(() => import('./BigEvents/Administration'));
const EditArena = lazy(() => import('./Arenas/EditArena'));
const EditEvent = lazy(() => import('./Events/EditEvent'));
const ChangePassword = lazy(() => import('./Security/ChangePassword'));
const GetNewPassword = lazy(() => import('./Security/GetNewPassword'));
const AdminEvent = lazy(() => import('./Events/AdminEvent'));
const AdminArena = lazy(() => import('./Arenas/AdminArena'));
const Cookies = lazy(() => import('./StaticPages/Cookies'));
const Privacy = lazy(() => import('./StaticPages/Privacy'));
const Places = lazy(() => import('./Arenas/Places'));
const AllSpots = lazy(() => import('./Arenas/AllSpots'));
const NotFound = lazy(() => import('./StaticPages/NotFound'));
const EventConfirm = lazy(() => import('./Events/EventConfirm'));
const Dashboard = lazy(() => import('./Dashboards/Player/Dashboard'));
const About = lazy(() => import('./StaticPages/About'));
const OrgDashboard = lazy(() => import('./Dashboards/Organizator/OrgDashboard'));
const SuperAdminPanel = lazy(() => import('./SuperAdminPanel'));
const ResetPassword = lazy(() => import('./Security/ResetPassword'));
const ConfirmEmail = lazy(() => import('./Security/ConfirmEmail'));
const Tools = lazy(() => import('./StaticPages/Tools'));
const Players = lazy(() => import('./Players/Players'));
const AllGroups = lazy(() => import('./Groups/AllGroups'));
const Chats = lazy(() => import('./Chats'));
const News = lazy(() => import('./News'));

const ProtectedRoute = (data: any) => {
    if (!data.user) {
        setCookie('PlayAirTempLink', window.location.pathname, 365);
        return <Navigate to={data.redirectPath} replace />;
    }

    return <Outlet />;
};

export const Navigation: FC = () => {
    const { userProfile } = useSelector<IState, IUserProfileReducer>(state => state.profile);

    return (
        <Suspense fallback={<Spinner size="large" />}>
            <Routes>
                <Route index element={<Home />} />
                <Route path="/index.html" element={<Home />} />
                <Route element={<ProtectedRoute user={userProfile} redirectPath="/login" />}>
                    <Route path="discount" element={<Discount />} />
                    <Route path="profile/:id" element={<Profile />} />
                    <Route path="profile/:id/dashboard" element={<Dashboard />} />
                    <Route path="profile/:id/tools" element={<Tools />} />
                    <Route path="org-dashboard" element={<OrgDashboard />} />
                    <Route path="arena/:id/event/:eventId/admin" element={<AdminEvent />} />
                    <Route path="spot/:id/event/:eventId/admin" element={<AdminEvent />} />
                    <Route path="arena/:id" element={<SingleArena />} />
                    <Route path="spot/:id" element={<SingleArena />} />
                    <Route path="arena/:id/event/:eventId" element={<SingleEvent />} />
                    <Route path="spot/:id/event/:eventId" element={<SingleEvent />} />
                    <Route path="big-event/:bigEventId/admin" element={<BigEventAdministration />} />
                    <Route path="big-event/:bigEventId/faction/:factionId" element={<SingleBigEvent />} />
                    <Route path="big-event/:bigEventId" element={<SingleBigEvent />} />
                    <Route path="arena/:id/event/:eventId/confirm" element={<EventConfirm />} />
                    <Route path="spot/:id/event/:eventId/confirm" element={<EventConfirm />} />
                    <Route path="arena/:id/admin" element={<AdminArena />} />
                    <Route path="spot/:id/admin" element={<AdminArena />} />
                    <Route path="edit-arena/:id?" element={<EditArena />} />
                    <Route path="edit-spot/:id?" element={<EditSpot />} />
                    <Route path="edit-event/:eventId?" element={<EditEvent />} />
                    <Route path="arena/:id/edit-event/:eventId?" element={<EditEvent />} />
                    <Route path="spot/:id/edit-event/:eventId?" element={<EditEvent />} />
                    <Route path="arena/:id/events" element={<Soon />} />
                    <Route path="spot/:id/events" element={<Soon />} />
                    <Route path="players" element={<Players />} />
                    <Route path="canabis" element={<SuperAdminPanel />} />
                    <Route path="services" element={<AllServices />} />
                    <Route path="service/:id" element={<SingleService />} />
                    <Route path="team/:id" element={<SingleGroup />} />
                    <Route path="team/:id/event/:eventId" element={<SingleEvent />} />
                    <Route path="team/edit-team/:id?" element={<EditGroup />} />
                    <Route path="services/edit-service/:id?" element={<EditService />} />
                    <Route path="notifications" element={<Notifications />} />
                    <Route path="change-password" element={<ChangePassword />} />
                    <Route path="administration" element={<Administration />} />
                    <Route path="administration/profile/:profileId" element={<Administration />} />
                    <Route path="administration/events" element={<Administration />} />
                    <Route path="administration/events/event/:eventId" element={<Administration />} />
                    <Route path="administration/arenas" element={<Administration />} />
                    <Route path="administration/arenas/arena/:arenaId" element={<Administration />} />
                    <Route path="administration/spots" element={<Administration />} />
                    <Route path="administration/spots/spot/:spotId" element={<Administration />} />
                    <Route path="administration/groups" element={<Administration />} />
                    <Route path="administration/groups/group/:groupId" element={<Administration />} />
                    <Route path="administration/bazarek" element={<Administration />} />
                    <Route path="administration/bazarek/offer/:offerId" element={<Administration />} />
                    <Route path="teams" element={<AllGroups />} />
                    <Route path="chats" element={<Chats />} />
                    <Route path="bazarek/edit-offer/:id?" element={<EditOffer />} />
                </Route>
                <Route path="bazarek" element={<Offer />} />
                <Route path="bazarek/offer/:id" element={<SingleOffer />} />
                <Route path="profile/:id/replica/:replicaId" element={<Replica />} />
                <Route path="profile/:id/gear/:gearId" element={<Gear />} />
                <Route path="profile/:id/equipment/:equipmentId" element={<Equipment />} />
                <Route path="get-password" element={<GetNewPassword />} />
                <Route path="reset-password-form" element={<ResetPassword />} />
                <Route path="confirm-email-form" element={<ConfirmEmail />} />
                <Route path="login" element={<Login />} />
                <Route path="news" element={<News />} />
                <Route path="register" element={<Register />} />
                <Route path="cookies" element={<Cookies />} />
                <Route path="privacy" element={<Privacy />} />
                <Route path="terms" element={<Terms />} />
                <Route path="about" element={<About />} />
                <Route path="contact" element={<Contact />} />
                <Route path="arenas" element={<AllArenas />} />
                <Route path="places" element={<Places />} />
                <Route path="spots" element={<AllSpots />} />
                <Route path="events" element={<AllEvents />} />
                <Route path="big-events" element={<AllBigEvents />} />
                <Route path="help" element={<Help />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
        </Suspense>
    );
};