import { Dispatch } from 'redux';
import * as queryString from 'query-string-for-all';

import { IArena } from '../entities/IArena';
import { IState } from '../reducers';
import { apiUrl } from '../configuration/url';
import { IS_ORGANIZATOR } from './types/userProfileTypes';

export const searchAllArenas = (payload: {name?: string, cities?: string[], states?: string[], statuses?: string[], textSearch?: string}): Promise<IArena[]> => ((dispatch: Dispatch, getState: () => IState) => {
    const token = getState().security.token;
    const userProfile = getState().profile.userProfile;
    const queryParams = queryString.stringify(payload);
    return fetch(`${apiUrl}/api/arena?sort=name&size=9999&${queryParams}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token || ''
        }
    }).then((res: any) => res.json()).then(res => res.content).then((response: IArena[]) => {
        dispatch({
            type: IS_ORGANIZATOR,
            isOrganizator: !!response?.find(elem => elem.headmaster?.id === userProfile?.id)
        });
        return response;
    });
}) as any;

export const getSingleArena = (arenaId: string): Promise<IArena> => ((dispatch: Dispatch, getState: () => IState) => {
    const token = getState().security.token;
    return fetch(`${apiUrl}/api/arena/${arenaId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token || ''
        }
    }).then((res: any) => res.json()).then((response: IArena) => {
        return response;
    });
}) as any;

export const removeSingleArena = (arenaId: string): Promise<IArena> => ((dispatch: Dispatch, getState: () => IState) => {
    const token = getState().security.token;
    return fetch(`${apiUrl}/api/arena/${arenaId}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token || ''
        }
    });
}) as any;

export const saveNewArena = (arena: IArena): Promise<IArena> => ((dispatch: Dispatch, getState: () => IState) => {
    const token = getState().security.token;
    return fetch(`${apiUrl}/api/arena`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token || ''
        },
        body: JSON.stringify(arena)
    }).then((res: any) => res.json()).then((response: IArena) => {
        return response;
    });
}) as any;

export const updateArena = (arenaId: string, arena: IArena): Promise<IArena> => ((dispatch: Dispatch, getState: () => IState) => {
    const token = getState().security.token;
    return fetch(`${apiUrl}/api/arena/${arenaId}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token || ''
        },
        body: JSON.stringify(arena)
    }).then((res: any) => res.json().content).then((response: IArena) => {
        return response;
    });
}) as any;